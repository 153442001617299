// Data service

export class DataService {

  MASTER_ADMIN_USER_TYPES = [
      {
        'id': 0,
        'name': 'Master'
      },
      {
        'id': 1,
        'name': 'Administrador'
      },
      {
        'id': 2,
        'name': 'Comun'
      },
      {
        'id': 3,
        'name': 'Oficial'
      },
    ];

  ADMIN_ADMIN_USER_TYPES = [
      {
        'id': 1,
        'name': 'Administrador'
      },
      {
        'id': 2,
        'name': 'Comun'
      },
      {
        'id': 3,
        'name': 'Oficial'
      },
    ];

  ADMIN_TYPES = {
    0: this.MASTER_ADMIN_USER_TYPES,
    1: this.ADMIN_ADMIN_USER_TYPES
  }

  VISIT_REGISTER_STATES = [
    {
      id:  0,
      name: 'Todas'
    },
    {
      id:  1,
      name: 'Completas'
    },
    {
      id:  2,
      name: 'Incompletas'
    },
  ];

  VISIT_REGISTER_FORMATS = [
    {
      id:  0,
      name: 'PDF'
    },
    {
      id:  1,
      name: 'EXCEL'
    },
    {
      id:  2,
      name: 'CSV'
    },
  ];

  getAdminTypes(typeId: number) {
    return this.ADMIN_TYPES[typeId];
  }

  visitRegisterStates() {
    return this.VISIT_REGISTER_STATES;
  }

  visitRegisterFormats() {
    return this.VISIT_REGISTER_FORMATS;
  }


}
