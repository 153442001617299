import { environment } from 'environments/environment';

export class BaseService {

  selectedObject: any;

  endpoint: string;
  serverUrl: string;

  constructor(
  ) {
    this.serverUrl = environment.server_url;
  }

  serverEndpoint() {
    return `${this.serverUrl}/${this.endpoint}`;
  }

  serverEndPlus(param: any) {
    return `${this.serverUrl}/${this.endpoint}/${param}`;
  }

  serverPlusList(lst: any[]) {
    const params = lst.join('/');
    return `${this.serverUrl}/${this.endpoint}/${params}`;
  }

}
