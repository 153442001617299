import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {ServiceConstants} from './service-constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  loggedIn = true;

  constructor(private http: HttpClient) {
    super();
    this.endpoint = ServiceConstants.ADMIN_USER;
  }

  isAuthenticated() {
    return this.loggedIn;
  }

  doLogin(data) {
    localStorage.setItem('adminUser', JSON.stringify(data));
    this.loggedIn = true;
  }

  login(adminUser: any) {
    return this.http.post(this.serverEndPlus('auth'), adminUser);
  }

  resetPassword(adminUser: any) {
    return this.http.post(this.serverEndPlus('reset'), adminUser);
  }

  requestReset(adminUser: any) {
    return this.http.post(this.serverEndPlus('token'), adminUser);
  }

  validateToken(token: any) {
    return this.http.get(this.serverEndPlus('token/' + token));
  }

  logout() {
    localStorage.removeItem('adminUser');
    this.loggedIn = false;
  }


  getLoggedUser() {
    const localStored = localStorage.getItem('adminUser');
    return JSON.parse(localStored);
  }

}

