// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  // server_url: 'http://local.k-9access.com/api', // local
  server_url: 'https://api.k-9access.com/api', // prod
  // server_url: 'https://api.k-9access.com:8001/api', // staging
  firebase: {
    apiKey: 'AIzaSyCiR5BdODJHXoPn3ZHmVijUJO8KagdSmkc',
    authDomain: 'k-9-access.firebaseapp.com',
    databaseURL: 'https://k-9-access.firebaseio.com',
    projectId: 'k-9-access',
    storageBucket: 'k-9-access.appspot.com',
    messagingSenderId: '149758591876'
  }

};
